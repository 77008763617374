import React, { useState } from "react";
import axios from 'axios';


class Addintake extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			name: '',
			mobile: '',
			mobile2: '',
			email: '',
			area: '',
			address: '',
			city: '',
			pincode: '',
			bname: '',
			bmobile: '',
			bmobile2: '',
			bemail: '',
			barea: '',
			baddress: '',
			bcity: '',
			bpincode: '',
			makemodel: '',
			model: '',
			serial: '',
			adapter: '',
			battery: '',
			drive: '',
			usb: '',
			accessories: '',
			power: '',
			hdd: '',
			case: '',
			problems: '',
			password: '',
			damages: '',
			ram: '',
		}

	}
	handleSubmit = event => {
		event.preventDefault();
		axios.post('http://localhost:3001/api/intakes/', {
			name: this.state.name,
			primary_contact: this.state.mobile,
			alternative_contact: this.state.mobile2,
			email: this.state.email,
			area: this.state.area,
			address: this.state.address,
			city: this.state.city,
			pincode: this.state.pincode,
			bname: this.state.bname,
			bmobile: this.state.bmobile,
			balternate_mobile: this.state.bmobile2,
			bemail: this.state.bemail,
			barea: this.state.barea,
			baddress: this.state.baddress,
			bcity: this.state.bcity,
			bpincode: this.state.bpincode,
			make: this.state.makemodel,
			model: this.state.model,
			serialno: this.state.serial,
			adapter: this.state.adapter,
			battery: this.state.battery,
			odrive: this.state.drive,
			usb: this.state.usb,
			accessories: this.state.accessories,
			power: this.state.power,
			hdd: this.state.hdd,
			carrycase: this.state.case,
			problem: this.state.problems,
			password: this.state.password,
			damages: this.state.damages,
			ram: this.state.ram,
			status: "Pending",
		})
			.then(res => {
				console.log(res);
				console.log(res.data);
				// window.location = "/retrieve" //This line of code will redirect you once the submission is succeed
			})
	}
	handleChange = event => {
		this.setState({
			[event.target.name]: event.target.value

		});
	}
	clearForm = () => {
		document.getElementById("myForm").reset();
	}
	render() {

		return (
			/*<form onSubmit={this.handleSubmit}>
				<label> First Name:
					<input type="text" name="firstname" onChange={this.handleChange} />
				</label>
				<label> Last Name:
					<input type="text" name="lastname" onChange={this.handleChange} />
				</label>
				<label> Email:
					<input type="text" name="email" onChange={this.handleChange} />
				</label>
				<label> Mobile:
					<input type="text" name="mobile" onChange={this.handleChange} />
				</label>
				<label> Address:
					<input type="text" name="address" onChange={this.handleChange} />
				</label>
				<label> Password:
					<input type="text" name="password" onChange={this.handleChange} />
				</label>
				<button type="submit"> Add </button>
			</form>*/

			<div class="page-wrapper">
				<div class="content container-fluid">

					<div class="page-header">
						<div class="row">
							<div class="col-sm-12">
								<h3 class="page-title">Intake</h3>
								<ul class="breadcrumb">
									<li class="breadcrumb-item"><a href="/">Dashboard</a></li>
									<li class="breadcrumb-item"><a href="/intake">Intake</a></li>
									<li class="breadcrumb-item active">Add Intake</li>
								</ul>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-md-12">
							<div class="card">
								<div class="card-body">
									<h4 class="card-title">Customer and Billing Information</h4>
									<form onSubmit={this.handleSubmit} id="myForm">
										<div class="row">
											<div class="col-md-3">
												<div class="form-group">
													<input type="text" class="form-control" name="name" onChange={this.handleChange} placeholder="Name" />
												</div>
												<div class="form-group">
													<input type="text" class="form-control" name="mobile" onChange={this.handleChange} placeholder="Primary Contact" />
												</div>
												<div class="form-group">
													<input type="text" class="form-control" name="mobile2" onChange={this.handleChange} placeholder="Alternative Contact" />
												</div>
												<div class="form-group">
													<input type="email" class="form-control" name="email" onChange={this.handleChange} placeholder="Email" />
												</div>
											</div>
											<div class="col-md-3">
												<div class="form-group">
													<input type="text" class="form-control" name="area" onChange={this.handleChange} placeholder="Area" />
												</div>
												<div class="form-group">
													<input type="text" class="form-control" name="address" onChange={this.handleChange} placeholder="Address" />
												</div>
												<div class="form-group">
													<input type="text" class="form-control" name="city" onChange={this.handleChange} placeholder="city" />
												</div>
												<div class="form-group">
													<input type="text" class="form-control" name="pincode" onChange={this.handleChange} placeholder="pincode" />
												</div>
											</div>
											<div class="col-md-3">
												<div class="form-group">
													<input type="text" class="form-control" name="bname" onChange={this.handleChange} placeholder="Name" />
												</div>

												<div class="form-group">
													<input type="text" class="form-control" name="bmobile" onChange={this.handleChange} placeholder="Primary Contact" />
												</div>
												<div class="form-group">
													<input type="text" class="form-control" name="bmobile2" onChange={this.handleChange} placeholder="Alternative Contact" />
												</div>
												<div class="form-group">
													<input type="email" class="form-control" name="bemail" onChange={this.handleChange} placeholder="Email" />
												</div>
											</div>
											<div class="col-md-3">
												<div class="form-group">
													<input type="text" class="form-control" name="barea" onChange={this.handleChange} placeholder="Area" />
												</div>
												<div class="form-group">
													<input type="text" class="form-control" name="baddress" onChange={this.handleChange} placeholder="Address" />
												</div>
												<div class="form-group">
													<input type="text" class="form-control" name="bcity" onChange={this.handleChange} placeholder="city" />
												</div>
												<div class="form-group">
													<input type="text" class="form-control" name="bpincode" onChange={this.handleChange} placeholder="pincode" />
												</div>
											</div>
										</div>
										<h4 class="card-title mt-4">Laptop Details</h4>
										<div class="row">
											<div class="col-md-3">
												<div class="form-group">
													<select class="form-control" name="makemodel" onChange={this.handleChange}>
														<option>Select Make Model</option>
														<option value="acer">Acer</option>
														<option value="lenova">Lenova</option>
														<option>HP</option>
														<option>Samsung</option>
														<option>Sony</option>
														<option>Others</option>
													</select>
												</div>
												<div class="form-group">
													<input type="text" class="form-control" name="model" onChange={this.handleChange} placeholder="Model" />
												</div>
												<div class="form-group">
													<input type="text" class="form-control" name="serial" onChange={this.handleChange} placeholder="Serial" />
												</div>
												<div class="form-group">
													<input type="text" class="form-control" name="battery" onChange={this.handleChange} placeholder="Battery" />
												</div>
											</div>
											<div class="col-md-3">
												<div class="form-group">
													<input type="text" class="form-control" name="ram" onChange={this.handleChange} placeholder="RAM" />
												</div>
												<div class="form-group">
													<input type="text" class="form-control" name="hdd" onChange={this.handleChange} placeholder="HDD" />
												</div>
												<div class="form-group">
													<select class="form-control" name="power" onChange={this.handleChange}>
														<option>Select Power Chord</option>
														<option value="yes">Yes</option>
														<option value="no">No</option>
													</select>
												</div>
												<div class="form-group">
													<input type="text" class="form-control" name="adapter" onChange={this.handleChange} placeholder="Adapter" />
												</div>
											</div>
											<div class="col-md-3">
												<div class="form-group">
													<input type="text" class="form-control" name="drive" onChange={this.handleChange} placeholder="Optical Drive" />
												</div>
												<div class="form-group">
													<input type="text" class="form-control" name="accessories" onChange={this.handleChange} placeholder="Other Accessories" />
												</div>
												<div class="form-group">
													<input type="text" class="form-control" name="usb" onChange={this.handleChange} placeholder="USB/External" />
												</div>
												<div class="form-group">
													<select class="form-control" name="case" onChange={this.handleChange}>
														<option>Select Carry Case</option>
														<option value="acer">Acer</option>
														<option value="lenova">Lenova</option>
														<option> HP</option>
														<option>Samsung</option>
														<option>Sony</option>
														<option>Others</option>
													</select>
												</div>
											</div>
											<div class="col-md-3">
												<div class="form-group">
													<input type="text" class="form-control" name="password" onChange={this.handleChange} placeholder="Password" />
												</div>
												<div class="form-group">
													<input type="text" class="form-control" name="damages" onChange={this.handleChange} placeholder="Damages and Scratches" />
												</div>
												<div class="form-group">
													<input type="text" class="form-control" name="problems" onChange={this.handleChange} placeholder="Problems Reported" />
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-md-12 d-flex justify-content-center">
												<div class="form-group">
													<button type="submit" class="btn btn-success">Add Customer</button>&nbsp;
													<button type="button" class="btn btn-danger" onClick={this.clearForm} >Cancel</button>&nbsp;
													<a href="/intake" class="btn btn-secondary">Back</a>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default Addintake