import React, { Component } from 'react'
import MyChild from './myChild'

class MyContainer extends Component {
  state = {
    name: 'foo'
  }

//   handleNameChange = name => {
//     this.setState({ name })
//   }

  render() {
    return (
      <MyChild name={this.state.name} />
    )
  }

}
export default MyContainer