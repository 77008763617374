import React from 'react'
function Leftside() {
    const submenu = {
        padding: "0"
    }
    return (

        <div className="sidebar" id="sidebar">
            <div className="sidebar-inner slimscroll">
                <div id="sidebar-menu" className="sidebar-menu">
                    <ul>
                        <li className="menu-title"><span>Main</span></li>
                        <li className="active">
                            <a href="/"> <i className="fas fa-home"></i> <span>Dashboard</span></a>
                        </li>
                        {/* <li>
                            <a href="/alumnies"><i className="fas fa-users"></i> <span>Intake</span></a>
                        </li>
                        <li>
                            <a href="/addbrand"><i className="fas fa-users"></i> <span> Brand</span></a>
                        </li> */}
                        <li class="submenu">
                            <a href="#"><i className="fas fa-users"></i> <span> Intakes </span> <span
                                class="menu-arrow"></span></a>
                            <ul>
                                <li><a href="/intake">Intakes</a></li>
                                <li><a href="/pendingintake">Pending Intakes</a></li>
                                <li><a href="/approvedintake">Approved Intakes</a></li>
                                <li><a href="/returnedintake">Returned Intakes</a></li>
                            </ul>
                        </li>
                        <li class="submenu">
                            <a href="#"><i className="fas fa-users"></i> <span> Stock </span> <span
                                class="menu-arrow"></span></a>
                            <ul>
                                <li><a href="/addbrand">Brand</a></li>
                                <li><a href="/addcategory">Category</a></li>
                                <li><a href="/addvendor">Vendor</a></li>
                                <li><a href="/addproduct">Product</a></li>
                            </ul>
                        </li>
                        {/* <li class="submenu">
                            <a href="#"><i className="fas fa-users"></i> <span> Intakes </span> <span
                                class="menu-arrow"></span></a>
                            <ul style={submenu}>
                                <li class="submenu">
                                    <a href="#" ><i className="fas fa-users"></i> <span style={{ float:'none'}}> Intakes </span> <span
                                        class="menu-arrow"></span></a>
                                    <ul style={submenu}>
                                        <li><a href="/intake">Intakes</a></li>
                                        <li><a href="/addintake">Add Intake</a></li>

                                    </ul>
                                </li>
                            </ul>

                        </li> */}

                    </ul>
                </div>
            </div>
        </div>
    )

}
export default Leftside;