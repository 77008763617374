import React from "react";

function SidebarLayout() {
    return (
        <div className="sidebar-layout">
            <div className="sidebar-content">
                <div className="sidebar-top">
                    <div className="container-fluid">
                        <div className="row  align-items-center">
                            <div className="col-xl-6 col-sm-6 col-12">
                                <div className="sidebar-logo">
                                    <a href="index.html" className="logo">
                                        <img src="assets/img/logo.png" alt="Logo" className="img-flex" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-xl-6 col-sm-6 col-12">
                                <a className="btn-closed" href="#"><i data-feather="x" className="feather-six"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
    )
}
export default SidebarLayout