import React from 'react'
function Rightside() {
    return (
        <div className="right-side-views">
            <ul className="sticky-sidebar siderbar-view">
                <li className="sidebar-icons">
                    <a className="toggle tipinfo open-layout open-siderbar" href="#" data-toggle="tooltip"
                        data-placement="left" data-bs-original-title="Tooltip on left">
                        <div className="tooltip-five ">
                            <i data-feather="layout" className="feather-five"></i>
                            <span className="tooltiptext">Check Layout</span>
                        </div>
                    </a>
                </li>
                <li className="sidebar-icons">
                    <a className="toggle tipinfo open-settings open-siderbar" href="#" data-toggle="tooltip"
                        data-placement="left" data-bs-original-title="Tooltip on left">
                        <div className="tooltip-five">
                            <i data-feather="settings" className="feather-five"></i>
                            <span className="tooltiptext">Demo Settings</span>
                        </div>
                    </a>
                </li>
                <li className="sidebar-icons">
                    <a className="toggle tipinfo" target="_blank"
                        href="https://themeforest.net/item/kanakku-bootstrap-admin-html-template/29436291?s_rank=11"
                        data-toggle="tooltip" data-placement="left" title="Tooltip on left">
                        <div className="tooltip-five">
                            <i data-feather="shopping-cart" className="feather-five"></i>
                            <span className="tooltiptext">Buy Now</span>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    )
}
export default Rightside;