import React from "react";
import { render } from "react-dom";
import axios from "axios";


class Invoice extends React.Component {

    state = {
        rows: [{}],
        amount: 0,
        discount: 0,
        bill_value: 0,
        net_amount: 0,
        products: [],
        mappingproducts: [{}],
        mappingmodels: [{}],
        invoicedetails: [],
        invoicenumber: 0,
        categories: [],
        brands: []

    };
    componentDidMount() {
        axios.get('http://localhost:3001/api/brand/').then(res => {
            //Storing users detail in state array object
            this.setState({
                brands: res.data.data,
            });
        });
        axios.get('http://localhost:3001/api/category/').then(res => {

            //Storing users detail in state array object
            this.setState({
                categories: res.data.data,
            });
        });
        axios.get('http://localhost:3001/api/product/').then(res => {

            //Storing users detail in state array object
            this.setState({
                products: res.data.data,
            });
        });
        axios.get('http://localhost:3001/api/invoice/').then(res => {

            //Storing users detail in state array object
            this.setState({
                invoicedetails: res.data.data,
            });
        });
    }
    handleSubmit = event => {
        event.preventDefault();
        const rowcount = this.state.rows.length;
        let response = [{}];
        let rrows = this.state.rows;
        const intakes = this.props.intake;
        // const invno = this.state.invoicenumber;
        for (let i = 0; i < rowcount; i++) {
            rrows[i]['invoice_number'] = document.getElementById('invoiceid').innerHTML
            rrows[i]['intake_id'] = intakes.intake_id
            rrows[i]['name'] = intakes.name
            rrows[i]['primary_contact'] = intakes.primary_contact
            rrows[i]['alternative_contact'] = intakes.alternative_contact
            rrows[i]['address'] = intakes.address
            rrows[i]['city'] = intakes.city
            rrows[i]['pincode'] = intakes.pincode
            rrows[i]['email'] = intakes.email
            rrows[i]['bill_value'] = document.getElementById('bill_value').innerHTML
            rrows[i]['discount'] = event.target.discount.value
            rrows[i]['net_amount'] = document.getElementById('net_amount').innerHTML

        }
        axios.post('http://localhost:3001/api/invoice/', { rrows })
            .then(res => {
                console.log(res.data.message);
                alert(res.data.message);
            })
    }
    // handleSubmit = event => {
    //     event.preventDefault();
    //     const rowcount = this.state.rows.length;
    //     let response = '';
    //     for (let i = 0; i < rowcount; i++) {
    //         axios.post('http://localhost:3001/api/invoice/', {
    //             intake_id: document.getElementById('intakeid').innerHTML,
    //             name: document.getElementById('name').innerHTML,
    //             primary_contact: document.getElementById('mobile').innerHTML,
    //             alternative_contact: document.getElementById('alternative_mobile').innerHTML,
    //             email: document.getElementById('email').innerHTML,
    //             address: document.getElementById('address').innerHTML,
    //             city: document.getElementById('city').innerHTML,
    //             pincode: document.getElementById('pincode').innerHTML,
    //             product: this.state.rows[i].product,
    //             brand: this.state.rows[i].brand,
    //             model: this.state.rows[i].model,
    //             serial_no: this.state.rows[i].serial_no,
    //             particulars: this.state.rows[i].particulars,
    //             quantity: this.state.rows[i].quantity,
    //             price: this.state.rows[i].price,
    //             amount: this.state.rows[i].amount,
    //             bill_value: document.getElementById('bill_value').innerHTML,
    //             discount: event.target.discount.value,
    //             net_amount: document.getElementById('net_amount').innerHTML,
    //         })

    //             .then(res => {
    //                 console.log(res.data);
    //                 console.log(res.data.message);
    //                 let ress = rowcount - 1;
    //                 if (i === ress) {
    //                     alert(res.data.message);
    //                 }
    //             })
    //     }
    // }
    handleChange = idx => e => {
        const { name, value } = e.target;
        const rows = [...this.state.rows];
        rows[idx][name] = value;
        const amount = rows[idx].quantity * rows[idx].price;
        rows[idx]['amount'] = amount;
        this.setState({
            rows,
        });

    };
    producthandleChange = idx => e => {

        let { name, value } = e.target;
        const rows = [...this.state.rows];
       // let mappingproducts = [...this.state.mappingproducts];
        //let mappingmodels = [...this.state.mappingmodels];
        const pdetails = value.split("-");
        let pdetailscount = pdetails.length;
        if (pdetailscount > 1) {
            rows[idx]['product'] = pdetails[0];
            rows[idx]['productid'] = pdetails[1];
        }
        else {
            rows[idx][name] = value;
        }


        const matchProduct = this.state.products;
        const product_count = matchProduct.length;
        //let pid = pdetails[1];
        let pid = value;
        for (let i = 0; i < product_count; i++) {

            if (rows[idx].category === matchProduct[i].category_name && rows[idx].brand === matchProduct[i].brand_name) {
                rows[idx]['mappingproducts'] = matchProduct
            } else {
                rows[idx]['mappingproducts'] = [];
                rows[idx]['product'] = '';
                rows[idx]['productid'] = '';
            }
            if (rows[idx].category === matchProduct[i].category_name && rows[idx].brand === matchProduct[i].brand_name && rows[idx].productid === matchProduct[i].product_id) {
                rows[idx]['mappingmodels'] = matchProduct
            } else {
                rows[idx]['mappingmodels'] = [];
                rows[idx]['model'] = '';
                rows[idx]['serial_no'] = '';
                rows[idx]['warranty'] = '';
                rows[idx]['price'] = '';
            }
            if (matchProduct[i].pdt_model === pid) {
                //rows[idx]['brand'] = matchProduct[i].brand_name;
                // rows[idx]['model'] = matchProduct[i].pdt_model;
                rows[idx]['serial_no'] = matchProduct[i].pdt_serialno;
                rows[idx]['warranty'] = matchProduct[i].pdt_warranty;
                rows[idx]['price'] = matchProduct[i].pdt_salesprice;
            }
        }
        console.log(rows);
        this.setState({
            rows,
        });
    };

    handleAddRow = () => {
        const item = {
            product: "",
            brand: "",
            model: "",
            serial_no: "",
            particulars: "",
            quantity: "",
            price: "",
            amount: "",
        };
        this.setState({
            rows: [...this.state.rows, item]
        });
    };
    handleRemoveRow = () => {
        this.setState({
            rows: this.state.rows.slice(0, -1)
        });
    };
    handleRemoveSpecificRow = (idx) => () => {
        const rows = [...this.state.rows]
        rows.splice(idx, 1)
        this.setState({ rows })
    }
    net_amount = () => {
        let subtotal = document.getElementById('bill_value').innerHTML;
        let discount = document.getElementById('discount').value;
        let net_amount = this.state.net_amount;
        net_amount = subtotal - discount;
        this.setState({
            net_amount,
        });
    }
    render() {
        //Invoice Id Generation
        const distinct = (value, index, self) => {
            return self.indexOf(value) === index
        }
        let invoicenumbers = [];
        this.state.invoicedetails.map((item, idx) => (
            invoicenumbers.push(item.invoice_number)
        ))
        let invoicenumbercount = invoicenumbers.filter(distinct).length;
        invoicenumbercount = invoicenumbercount + 1;
        const year = new Date().getFullYear().toString().slice(-2);
        const month = new Date().getMonth() + 1;
        const daate = new Date().getDate();
        let invoicenumber = 'TECH' + year + '_INV' + month + daate + invoicenumbercount;
        // Totoal, Sub total, discount, Netamount Calculation
        const subtotal = this.state;
        const rowcount = subtotal.rows.length;
        let discount = this.state.discount;
        let bill_value = this.state.bill_value;
        let net_amount = this.state.net_amount;
        for (let i = 0; i < rowcount; i++) {
            if (subtotal.rows[i]['amount']) {
                bill_value = bill_value + subtotal.rows[i]['amount'];
                net_amount = bill_value;
            }
        }
        const test = this.props.intake;
        //setting state mapping models & Products
        let mappingproducts = [];
        let mappingmodels = [];
        return (
            <div class="page-wrapper">
                <div class="content container-fluid">

                    <div class="page-header">
                        <div class="row">
                            <div class="col-sm-12">
                                <h3 class="page-title">Invoice</h3>
                                <ul class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="/">Dashboard</a></li>
                                    <li class="breadcrumb-item"><a href="/estimate">Estimates</a></li>
                                    <li class="breadcrumb-item active">Add Estimate</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <form action="#" onSubmit={this.handleSubmit}>
                                        <label style={{ color: '#7b3fff' }} >Invoice Number : <span id="invoiceid">{invoicenumber}</span></label>

                                        <div class="row">
                                            <div class="col-md-3  mt-3">
                                                <div class="form-group">
                                                    <label>INTAKE ID: <span name="intakeid" id="intakeid">{test.intake_id}</span></label>

                                                </div>
                                            </div>
                                            <div class="col-md-3  mt-3">
                                                <div class="form-group">
                                                    <label>Name :  <span id="name">{test.name}</span></label>
                                                </div>
                                            </div>
                                            <div class="col-md-3  mt-3">
                                                <div class="form-group">
                                                    <label>MOBILE : <span id="mobile">{test.primary_contact}</span></label>

                                                </div>
                                            </div>
                                            <div class="col-md-3 mt-3">
                                                <div class="form-group">
                                                    <label>Alternate Number: <span id="alternative_mobile">{test.alternative_contact}</span></label>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>ADDRESS: <span id="address">{test.address}</span></label>
                                                </div>
                                            </div>
                                            <div class="col-md-3 ">
                                                <div class="form-group">
                                                    <label>CITY :  <span id="city">{test.city}</span></label>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>PINCODE : <span id="pincode">{test.pincode}</span></label>

                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>EMAIL: <span id="email">{test.email}</span></label>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="table-responsive mt-4">
                                            <table class="table table-center table-hover" id="tbl">
                                                <thead>
                                                    <tr>
                                                        <th width="10%">Category Name</th>
                                                        <th width="10%">Brand Name</th>
                                                        <th width="10%">Product Name</th>
                                                        <th width="10%">Model Number</th>
                                                        <th width="10%">Serial Number</th>
                                                        <th width="10%">Warranty</th>
                                                        <th width="10%">Particulars</th>
                                                        <th width="7%">Quantity</th>
                                                        <th width="10%">Price</th>
                                                        <th width="10%">Amount</th>
                                                        <th width="2%">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody id="estimate_table">
                                                    {this.state.rows.map((item, idx) => {
                                                        if (item.mappingmodels !== undefined) {
                                                            mappingmodels = item.mappingmodels;
                                                        }
                                                        if (item.mappingproducts !== undefined) {
                                                            mappingproducts = item.mappingproducts;
                                                        }
                                                        return (

                                                            <tr key={idx}>
                                                                <td>
                                                                    <select class="form-control" name="category" onChange={this.producthandleChange(idx)}  >
                                                                        <option>Select Category</option>
                                                                        {this.state.categories.map((citem, cid) => {
                                                                            return (
                                                                                <option key={cid} value={citem.category_name}>{citem.category_name}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                    {/* <input type="text" class="form-control" name="product" value={this.state.rows[idx].product}
                                                                    onChange={this.handleChange(idx)} /> */}
                                                                </td>
                                                                <td>
                                                                    <select class="form-control" name="brand" onChange={this.producthandleChange(idx)}  >
                                                                        <option>Select Brand</option>
                                                                        {this.state.brands.map((bitem, bid) => {
                                                                            return (
                                                                                <option key={bid} value={bitem.brand_name}>{bitem.brand_name}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <select class="form-control" name="product" onChange={this.producthandleChange(idx)}  >
                                                                        <option>Select Product</option>
                                                                        {mappingproducts.map((pitem, pid) => {
                                                                            // if (this.state.rows[idx].category === pitem.category_name && this.state.rows[idx].brand === pitem.brand_name) {
                                                                            return (
                                                                                <option key={pid} value={pitem.product_name + '-' + pitem.product_id}>{pitem.product_name}</option>
                                                                            )
                                                                            //  }
                                                                        })}
                                                                    </select>
                                                                    {/* <input type="text" class="form-control" name="product" value={this.state.rows[idx].product}
                                                                    onChange={this.handleChange(idx)} /> */}
                                                                </td>
                                                                {/* <td>
                                                                <input type="text" class="form-control" name="brand" value={this.state.rows[idx].brand}
                                                                    onChange={this.handleChange(idx)} />
                                                            </td> 
                                                            <td>
                                                                <input type="text" class="form-control" name="model" value={this.state.rows[idx].model}
                                                                    onChange={this.handleChange(idx)} />
                                                                </td>*/}

                                                                <td>
                                                                    <select class="form-control" name="model" onChange={this.producthandleChange(idx)}  >
                                                                        <option>Select Model</option>
                                                                        {mappingmodels.map((pitem, pid) => {
                                                                            return (

                                                                                <option key={pid} value={pitem.pdt_model}>{pitem.pdt_model}</option>
                                                                            )
                                                                        })
                                                                        }

                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <input type="text" class="form-control" name="warranty" value={this.state.rows[idx].warranty}
                                                                        onChange={this.handleChange(idx)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" class="form-control" name="serial_no" value={this.state.rows[idx].serial_no}
                                                                        onChange={this.handleChange(idx)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" class="form-control" name="particulars" value={this.state.rows[idx].particulars}
                                                                        onChange={this.handleChange(idx)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" class="form-control" name="quantity" value={this.state.rows[idx].quantity}
                                                                        onChange={this.handleChange(idx)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" class="form-control" name="price" value={this.state.rows[idx].price}
                                                                        onChange={this.handleChange(idx)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" class="form-control" name="amount" value={this.state.rows[idx].amount}
                                                                        disabled />
                                                                </td>
                                                                <td class="add-remove text-end">
                                                                    <i class="fas fa-plus-circle" onClick={this.handleAddRow}></i> <i
                                                                        class="fas fa-minus-circle" onClick={this.handleRemoveSpecificRow(idx)}></i>
                                                                </td>
                                                            </tr>

                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="table-responsive mt-4">
                                            <table class="table table-stripped table-center table-hover">
                                                <thead></thead>
                                                <tbody>
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td class="text-end">Sub Total</td>
                                                        <td class="text-end"><span id="bill_value"> {bill_value} </span></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td class="text-end">Discount</td>
                                                        <td class="text-end"><input type="text" id="discount" class="form-control" onChange={this.net_amount} defaultValue={discount} /></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td class="text-end">Total</td>
                                                        <td class="text-end"><span id="net_amount">{this.state.net_amount === 0 ? net_amount : this.state.net_amount}</span></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-end mt-4">
                                            <button type="submit" class="btn btn-primary">Add Invoice</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Invoice;