import React from "react";
//import { render } from "react-dom";
import axios from "axios";
//import { type } from "@testing-library/user-event/dist/type";

//import Invoice from "./Invoice";

class Addproduct extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            productqty: '',
            vendordetails: [],
            productdetails: [],
            product: [],
            productids: [],
            productrows: [{}],
            purchaseprice: 0,
            salesprice: 0,
            Error: '',
            vendors: [],
            brands: [],
            categories: [],
            vname: ''
        };

        this.productqty = this.productqty.bind(this);
        this.vname = this.vendorinputhandleChange.bind(this);
        this.productdetails = this.productqty.bind(this);
    }
    productqty(e) {
        let rows = [];
        let error = this.state.Error;
        var numrows = e.target.value;
        if (typeof numrows !== "undefined") {
            if (!numrows.match(/^[1-9\b]+$/)) {
                error = "Product Quantity allowed only numbers";
            }
            else {
                error = ''
            }
            this.setState({
                Error: error
            })
        }

        //var i = rows.length;
        for (var i = rows.length; i < numrows; i++) {
            const item = {
                model: "",
                serial_no: "",
                warranty: "",
                desc: "",
                pprice: "",
                sprice: "",
                formIsValid: true,
                isError: {
                    model: '',
                    serial_no: '',
                    warranty: '',
                    desc: '',
                    pprice: '',
                    sprice: '',
                }
            };

            rows = [...rows, item];
        }
        this.setState({
            productqty: e.target.value,
            productdetails: [...rows]
        });
    }
    vendorinputhandleChange = e => {
        const { value } = e.target;

        const vendordetails = this.state.vendordetails;
        const vdetails = value.split("-");
        vendordetails['vname'] = vdetails[0];
        vendordetails['vmobile'] = vdetails[1];

        this.setState({
            vname: vdetails[0],
            vendordetails,
        });
        console.log(vendordetails);
        console.log(this.state.vendordetails);

    };
    inputhandleChange = e => {
        const { name, value } = e.target;

        const vendordetails = this.state.vendordetails;
        vendordetails[name] = value;
        this.setState({
            vendordetails,
        });
        console.log(vendordetails);
        console.log(this.state.vendordetails);
    };
    handleChange = idx => e => {
        const { name, value } = e.target;

        const productdetails = [...this.state.productdetails];
        productdetails[idx][name] = value;

        // const amount = productdetails[idx].quantity * productdetails[idx].price;
        // productdetails[idx]['amount'] = amount;
        this.setState({
            productdetails,
        });
    };
    clearForm = idx => e => {
        const productdetails = [...this.state.productdetails];
        productdetails[idx].model = '';
        productdetails[idx].serial_no = '';
        productdetails[idx].warranty = '';
        productdetails[idx].desc = '';
        productdetails[idx].pprice = '';
        productdetails[idx].sprice = '';
        this.setState({
            productdetails,
        });
    }
    handleValidation() {
        let fields = this.state.productdetails;
        let formIsValid = true;
        //loop
        Object.values(fields).forEach(val => {
            // isError = val.isError;
            if (!val.model) {
                val.isError.model = "Model cannot be empty";
                formIsValid = false;
            } else {
                val.isError.model = "";
                formIsValid = true;
            }

            if (!val.serial_no) {
                val.isError.serial_no = "Serial Number cannot be empty";
                formIsValid = false;
            }
            else {
                val.isError.serial_no = "";
                formIsValid = true;
            }

            if (!val.warranty) {
                val.isError.warranty = "Warranty cannot be empty";
                formIsValid = false;
            }
            else {
                val.isError.warranty = "";
                formIsValid = true;
            }

            if (!val.desc) {
                val.isError.desc = "Description cannot be empty";
                formIsValid = false;
            } else {
                val.isError.desc = "";
                formIsValid = true;
            }

            // if (!val.pprice) {
            //     val.isError.pprice = "Purchase price cannot be empty";
            //     formIsValid = false;
            // }
            // else {
            //     val.isError.pprice = "";
            //     formIsValid = true;
            // }
            if (typeof val.pprice !== "undefined") {
                if (!val.pprice.match(/^[0-9\b]+$/)) {
                    formIsValid = false;
                    val.isError.pprice = "Purchase price allowed only numbers";
                }
            }
            if (!val.sprice) {
                val.isError.sprice = "Sales price cannot be empty";
                formIsValid = false;
            }
            else {
                val.isError.sprice = "";
                formIsValid = true;
            }

        });


        //Name
        // if (!fields["name"]) {
        //     formIsValid = false;
        //     errors["name"] = "Cannot be empty";
        // }

        // if (typeof fields["name"] !== "undefined") {
        //     if (!fields["name"].match(/^[a-zA-Z]+$/)) {
        //         formIsValid = false;
        //         errors["name"] = "Only letters";
        //     }
        // }

        // //Email
        // if (!fields["email"]) {
        //     formIsValid = false;
        //     errors["email"] = "Cannot be empty";
        // }

        // if (typeof fields["email"] !== "undefined") {
        //     let lastAtPos = fields["email"].lastIndexOf("@");
        //     let lastDotPos = fields["email"].lastIndexOf(".");

        //     if (
        //         !(
        //             lastAtPos < lastDotPos &&
        //             lastAtPos > 0 &&
        //             fields["email"].indexOf("@@") == -1 &&
        //             lastDotPos > 2 &&
        //             fields["email"].length - lastDotPos > 2
        //         )
        //     ) {
        //         formIsValid = false;
        //         errors["email"] = "Email is not valid";
        //     }
        // }

        //this.setState({ errors: errors });
        this.setState({
            productdetails: fields,
        });
        console.log(formIsValid);
        return formIsValid;

    }
    handleSubmit = e => {
        e.preventDefault();
        if (this.handleValidation()) {
            const rowcount = this.state.productdetails.length;
            let pdtresponse = this.state.productdetails;
            //pdtresponse[0]['vendor_name'] = this.state.vendordetails.vname;
            console.log(pdtresponse);

            for (let i = 0; i < rowcount; i++) {
                console.log(i);

                pdtresponse[i]['vendor_name'] = this.state.vendordetails.vname;
                pdtresponse[i]['vendor_mobile'] = this.state.vendordetails.vmobile;
                pdtresponse[i]['purchase_date'] = this.state.vendordetails.vdate;
                pdtresponse[i]['vendor_billno'] = this.state.vendordetails.vbillno;
                pdtresponse[i]['product_id'] = document.getElementById('productid').innerHTML;
                pdtresponse[i]['product_name'] = this.state.vendordetails.pname;
                pdtresponse[i]['brand_name'] = this.state.vendordetails.brandname;
                pdtresponse[i]['category_name'] = this.state.vendordetails.categoryname;
                pdtresponse[i]['pdt_qty'] = this.state.productqty;
                pdtresponse[i]['pdt_model'] = this.state.productdetails[i].model;
                pdtresponse[i]['pdt_serialno'] = this.state.productdetails[i].serial_no;
                pdtresponse[i]['pdt_warranty'] = this.state.productdetails[i].warranty;
                pdtresponse[i]['pdt_desc'] = this.state.productdetails[i].desc;
                pdtresponse[i]['pdt_purchase_price'] = this.state.productdetails[i].pprice;
                pdtresponse[i]['pdt_salesprice'] = this.state.productdetails[i].sprice;
                pdtresponse[i]['pdt_total_pamount'] = document.getElementById('purchaseprice').innerHTML;
                pdtresponse[i]['pdt_total_samount'] = document.getElementById('salesprice').innerHTML;
                pdtresponse[i]['pdt_profit'] = document.getElementById('profit').innerHTML;

            }
            axios.post('http://localhost:3001/api/product/', { pdtresponse })
            .then(res => {
                console.log(res.data.message);
                alert(res.data.message);
            })
        }
    };
    /* handleSubmit = e => {
         e.preventDefault();
         if (this.handleValidation()) {
             const rowcount = this.state.productdetails.length;
             let response = '';
             for (let i = 0; i < rowcount; i++) {
                 axios.post('http://localhost:3001/api/product/', {
                     vendor_name: this.state.vendordetails.vname,
                     vendor_mobile: this.state.vendordetails.vmobile,
                     purchase_date: this.state.vendordetails.vdate,
                     vendor_billno: this.state.vendordetails.vbillno,
                     product_id: document.getElementById('productid').innerHTML,
                     product_name: this.state.vendordetails.pname,
                     brand_name: this.state.vendordetails.brandname,
                     category_name: this.state.vendordetails.categoryname,
                     pdt_qty: this.state.productqty,
                     pdt_model: this.state.productdetails[i].model,
                     pdt_serialno: this.state.productdetails[i].serial_no,
                     pdt_warranty: this.state.productdetails[i].warranty,
                     pdt_desc: this.state.productdetails[i].desc,
                     pdt_purchase_price: this.state.productdetails[i].pprice,
                     pdt_salesprice: this.state.productdetails[i].sprice,
                     pdt_total_pamount: document.getElementById('purchaseprice').innerHTML,
                     pdt_total_samount: document.getElementById('salesprice').innerHTML,
                     pdt_profit: document.getElementById('profit').innerHTML
 
 
                 })
                     .then(res => {
                         console.log(res);
                         console.log(res.data);
                         // window.location = "/retrieve" //This line of code will redirect you once the submission is succeed
                     })
             }
         }
     };*/
    componentDidMount() {
        //Get all users details in bootstrap table
        axios.get('http://localhost:3001/api/vendor/').then(res => {

            //Storing users detail in state array object
            this.setState({
                vendors: res.data.data,
            });
        });

        axios.get('http://localhost:3001/api/brand/').then(res => {

            //Storing users detail in state array object
            this.setState({
                brands: res.data.data,
            });
        });


        axios.get('http://localhost:3001/api/category/').then(res => {

            //Storing users detail in state array object
            this.setState({
                categories: res.data.data,
            });
        });

        axios.get('http://localhost:3001/api/product/').then(res => {

            //Storing users detail in state array object
            this.setState({
                productids: res.data.data,
            });
        });


    }
    render() {
        // Product ID Generatio
        const distinct = (value, index, self) => {
            return self.indexOf(value) === index
        }
        let productnumbers = [];
        this.state.productids.map((item, idx) => (
            productnumbers.push(item.product_id)
        ))
        let productnumbercount = productnumbers.filter(distinct).length;
        productnumbercount = productnumbercount + 1;
        const year = new Date().getFullYear().toString().slice(-2);
        const month = new Date().getMonth() + 1;
        const daate = new Date().getDate();
        let productid = 'TECH' + year + '_PDT' + month + daate + productnumbercount;

        const vendors = this.state.productdetails;
        const product = this.state.productdetails;
        const rowcount = this.state.productdetails.length;
        let purchaseprice = this.state.purchaseprice;
        let salesprice = this.state.salesprice;
        let profit = 0;
        for (let i = 0; i < rowcount; i++) {
            if (product[i]['pprice']) {
                purchaseprice = purchaseprice + Number(product[i]['pprice']);
            }
            if (product[i]['pprice']) {
                salesprice = salesprice + Number(product[i]['sprice']);
            }
            profit = salesprice - purchaseprice;
        }
        let btnDisabled = <button type="submit" className="btn btn-primary" disabled>Add Product</button>
        return (
            <div class="page-wrapper">
                <div class="content container-fluid">
                    <div class="page-header">
                        <div class="row">
                            <div class="col-sm-12">
                                <h3 class="page-title">Product Details</h3>
                                <ul class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="/">Dashboard</a></li>
                                    <li class="breadcrumb-item"><a href="/estimate">Product</a></li>
                                    <li class="breadcrumb-item active">Product Details</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <form action="#" onSubmit={this.handleSubmit.bind(this)}>
                                        <label style={{ color: '#7b3fff' }} >Product ID : <span id="productid">{productid}</span></label>
                                        <div class="row">
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>Vendor Name{this.state.vname}</label>
                                                    <select class="form-control" name="vname" onChange={this.vendorinputhandleChange} >
                                                        <option>Select Vendor Name</option>
                                                        {this.state.vendors.map((vitem, vid) => {
                                                            return (
                                                                <option key={vid} value={vitem.name + '-' + vitem.mobile}>{vitem.name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>Vendor Mobile</label>
                                                    <input type="text" class="form-control" name="vmobile" placeholder="Vendor Mobile Number" onChange={this.inputhandleChange} value={this.state.vendordetails.vmobile} />
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>Date of Purchase</label>
                                                    <input type="date" class="form-control" name="vdate" placeholder="" onChange={this.inputhandleChange} value={this.state.vendordetails.vdate} />
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>Vendor Bill No</label>
                                                    <input type="text" class="form-control" name="vbillno" placeholder="Enter Vendor Bill No" onChange={this.inputhandleChange} value={this.state.vendordetails.vbillno} />
                                                </div>
                                            </div>


                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>Product Name</label>
                                                    <input type="text" class="form-control" name="pname" placeholder="Enter Your Product Name" onChange={this.inputhandleChange} value={this.state.vendordetails.pname} />
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>Brand Name</label>
                                                    <select class="form-control" name="brandname" onChange={this.inputhandleChange} value={this.state.vendordetails.brandname}>
                                                        <option>Select Brand Name</option>
                                                        {this.state.brands.map((bitem, bid) => {
                                                            return (
                                                                <option key={bid} value={bitem.brand_name}>{bitem.brand_name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>Category Name</label>
                                                    <select class="form-control" name="categoryname" onChange={this.inputhandleChange} value={this.state.vendordetails.categoryname}>
                                                        <option>Select Category Name</option>
                                                        {this.state.categories.map((citem, cid) => {
                                                            return (
                                                                <option key={cid} value={citem.category_name}>{citem.category_name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>Product Quantity </label>
                                                    {/* <input type="text" class="form-control" name="quantity" placeholder="Enter Your Product Quantity" onChange={(e) => this.addRow(this.value)} /> */}
                                                    <input type="text" class="form-control" name="quantity" placeholder="Enter Your Product Quantity" onChange={this.productqty} value={this.state.productqty} />
                                                    <span style={{ color: "red" }}> {this.state.Error}</span>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="table-responsive mt-4">
                                            <table class="table table-center table-hover" id="tbl">
                                                <thead>
                                                    <tr>
                                                        <th>Model</th>
                                                        <th>Serial Number</th>
                                                        <th>Warranty</th>
                                                        <th>Description</th>
                                                        <th>Purchase Price</th>
                                                        <th>Sales Price</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody id="estimate_table">

                                                    {this.state.productdetails.map((items, idx) => {
                                                        if (this.state.productqty > 0) {
                                                            btnDisabled = <button type="submit" className="btn btn-primary">Add Product</button>
                                                        }

                                                        return (
                                                            <tr key={idx}>
                                                                <td>
                                                                    <input type="text" className={items.isError.model !== '' ? 'form-control  is-invalid' : 'form-control'} name="model" value={this.state.productdetails[idx].model}
                                                                        onChange={this.handleChange(idx)} />
                                                                    <span style={{ color: "red" }}> {this.state.productdetails[idx].isError.model}</span>
                                                                </td>
                                                                <td>
                                                                    <input type="text" className={items.isError.serial_no !== '' ? 'form-control  is-invalid' : 'form-control'} name="serial_no" value={this.state.productdetails[idx].serial_no}
                                                                        onChange={this.handleChange(idx)} />
                                                                    <span style={{ color: "red" }}>{this.state.productdetails[idx].isError.serial_no}</span>
                                                                </td>
                                                                <td>
                                                                    <input type="text" className={items.isError.warranty !== '' ? 'form-control  is-invalid' : 'form-control'} name="warranty" value={this.state.productdetails[idx].warranty}
                                                                        onChange={this.handleChange(idx)} />
                                                                    <span style={{ color: "red" }}>{this.state.productdetails[idx].isError.warranty}</span>

                                                                </td>
                                                                <td>
                                                                    <input type="text" className={items.isError.desc !== '' ? 'form-control  is-invalid' : 'form-control'} name="desc" value={this.state.productdetails[idx].desc}
                                                                        onChange={this.handleChange(idx)} />
                                                                    <span style={{ color: "red" }}>{this.state.productdetails[idx].isError.desc}</span>
                                                                </td>
                                                                <td>
                                                                    <input type="text" className={items.isError.pprice !== '' ? 'form-control  is-invalid' : 'form-control'} name="pprice" value={this.state.productdetails[idx].pprice}
                                                                        onChange={this.handleChange(idx)} />
                                                                    <span style={{ color: "red" }}>{this.state.productdetails[idx].isError.pprice}</span>
                                                                </td>
                                                                <td>
                                                                    <input type="text" className={items.isError.sprice !== '' ? 'form-control  is-invalid' : 'form-control'} name="sprice" value={this.state.productdetails[idx].sprice}
                                                                        onChange={this.handleChange(idx)} />
                                                                    <span style={{ color: "red" }}>{this.state.productdetails[idx].isError.sprice}</span>
                                                                </td>
                                                                <td class="add-remove text-end">
                                                                    <i class="fas fa-minus-circle" onClick={this.clearForm(idx)}></i>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    }


                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="table-responsive mt-4">
                                            <table class="table table-stripped table-center table-hover">
                                                <thead></thead>
                                                <tbody>
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td class="text-end">Purchase Amount</td>
                                                        <td class="text-end"><span id="purchaseprice"> {purchaseprice} </span></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td class="text-end">Sales  Amount</td>
                                                        <td class="text-end"><span id="salesprice"> {salesprice} </span></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td class="text-end">Profit</td>

                                                        <td class="text-end"> {Math.sign(profit) > 0 ? <span id="profit" className="text-success">{profit}</span> : <span id="profit" className="text-danger">{profit}</span>}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-end mt-4">
                                            {btnDisabled}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Addproduct;