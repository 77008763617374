import React, { Component } from 'react'

class MyChild extends Component {

//   handleInputChange = event => {
//     this.props.onNameChange(event.target.value)
//   }

  render() {
    return (
      <div>
        {/* <input type="text" onChange={this.handleInputChange} value={this.props.name} /> */}
        <div>The name is: {this.props.name}</div>
      </div>
    )
  }

}

export default MyChild