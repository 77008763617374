import React from "react";
function Header() {
    return (
        <div className="header">

            <div className="header-left">
                <a href="/" className="logo">
                    <img src="assets/img/logo-small.png" alt="Logo" />
                </a>
                <a href="/" className="white-logo">
                    <img src="assets/img/logo-white.png" alt="Logo" />
                </a>
                <a href="/" className="logo logo-small">
                    <img src="assets/img/logo-small.png" alt="Logo" width="30" height="30" />
                </a>
            </div>


            <a href="/" id="toggle_btn">
                <i className="fas fa-bars"></i>
            </a>


            <div className="top-nav-search">
                <form>
                    <input type="text" className="form-control" placeholder="Search here" />
                    <button className="btn" type="submit"><i className="fas fa-search"></i></button>
                </form>
            </div>


            <a className="mobile_btn" id="mobile_btn">
                <i className="fas fa-bars"></i>
            </a>


            <ul className="nav nav-tabs user-menu">





                <li className="nav-item dropdown has-arrow main-drop">
                    <a href="/" className="dropdown-toggle nav-link" data-bs-toggle="dropdown">
                        <span className="user-img">
                            <img src="assets/img/profiles/avatar-01.jpg" alt="" />
                            <span className="status online"></span>
                        </span>
                        <span>Admin</span>
                    </a>
                    <div className="dropdown-menu">
                        <a className="dropdown-item" href="/"><i data-feather="log-out" className="me-1"></i> Logout</a>
                    </div>
                </li>

            </ul>

        </div>
    )
}
export default Header  
