import React from "react";
import { CSVLink } from "react-csv";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import axios from 'axios';

const headers = [
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "Email", key: "email" },
    { label: "Mobile", key: "mobile" },
    { label: "Address", key: "address" },
    { label: "Password", key: "password" }
];

class PendingIntakes extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            intakedetails: [],
            problem_identified: '',
            status: '',
        }
        this.csvLinkEl = React.createRef();
    }
    componentDidMount() {
        //Get all users details in bootstrap table
        axios.get('http://localhost:3001/api/intakes/').then(res => {

            //Storing users detail in state array object
            this.setState({
                data: res.data.data,
            });


        });
        //initialize datatable
        $(document).ready(function () {
            setTimeout(function () {
                $('#example').DataTable(
                    {
                        pagingType: 'full_numbers',
                        pageLength: 10,
                        processing: true,
                        dom: 'Bfrtip',
                        buttons: [
                            {
                                extend: 'csvHtml5',
                                text: '<i class="fas fa-file-csv"></i>',
                                titleAttr: 'Export to CSV',
                                className: 'btn btn-primary me-1',
                                exportOptions: {
                                    columns: ':visible'
                                }
                            },
                            {
                                extend: 'print',
                                text: '<i class="fas fa-file-pdf"></i>',
                                titleAttr: 'Export to PDF',
                                className: 'btn btn-primary me-1',
                                exportOptions: {
                                    columns: [':visible']
                                }
                            },
                            {
                                extend: 'colvis',
                                text: '<i class="fas fa-eye"></i> Visible Coulmns',
                                titleAttr: 'Click on the Coulmn to Disable Visibility',
                                className: 'btn btn-primary me-1',
                                exportOptions: {
                                    columns: ':visible'
                                }
                            },
                        ],

                    },
                );
            }, 1000);
        });

    }
    get_intake_details(id) {
        axios.get('http://localhost:3001/api/intakes/' + id).then(res => {
            this.setState({
                redirect: true,
                intakedetails: res.data.data[0],
            });
        });

    }
    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    update_intake_details(id) {
        // console.log(id);
        axios.patch('http://localhost:3001/api/intakes/',
            {
                intake_id: id,
                problem_identified: this.state.problem_identified,
                status: this.state.status,
            }
        ).then(res => {
            console.log(res);
            console.log(res.data);
        });

    }
    downloadReport = async () => {
        this.componentDidMount();
        setTimeout(() => {
            this.csvLinkEl.current.link.click();
        });

    }

    render() {
        const buttonAlign = {
            marginTop: "1.5em"
        }
        const { data } = this.state;
        const makemodel = this.state.intakedetails.make + "" + this.state.intakedetails.model;

        return (
            <div class="page-wrapper">

                <div class="content container-fluid">
                    <div class="page-header">
                        <div class="row align-items-center">
                            <div class="col">
                                <h3 class="page-title">Intakes</h3>
                                <ul class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="/">Dashboard</a>
                                    </li>
                                    <li class="breadcrumb-item active">Intakes</li>
                                </ul>
                            </div>
                            <div class="col-auto" id="test">
                                <a href="addintake" class="btn btn-primary me-1">
                                    <i class="fas fa-plus"></i>
                                </a>
                                {/* <a class="btn btn-primary filter-btn" href="javascript:void(0);" id="filter_search">
                                    <i class="fas fa-plus"></i>
                                </a> */}

                            </div>
                        </div>
                    </div>

                    <div id="filter_inputs" class="card filter-card">
                        <div class="card-body pb-0">
                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Intake ID</label>
                                        <input type="text" class="form-control" name="intakeid" value={this.state.intakedetails.intake_id} disabled="true" />
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Make Model</label>
                                        <input type="text" class="form-control" name="makemodel" onChange={this.handleChange} value={makemodel} disabled="true" />
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Problem Reported</label>
                                        <input type="text" class="form-control" name="problem" onChange={this.handleChange} defaultValue={this.state.intakedetails.problem} disabled="true" />
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Problem Identified</label>
                                        <input type="text" class="form-control" name="problem_identified" onChange={this.handleChange} placeholder="Problem Identified" />
                                    </div>
                                </div>
                                <div class="col">
                                    <label>Status</label>
                                    <div class="form-group">
                                        <select class="form-control" name="status" onChange={this.handleChange}>
                                            <option>Select Intake Status</option>
                                            <option value="Pending">Pending</option>
                                            <option value="Approved">Approved</option>
                                            <option value="Returned">Returned</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-1">
                                    <div class="form-group">
                                        <button type="submit" className="btn btn-primary text-end" style={buttonAlign} onClick={e => { this.update_intake_details(this.state.intakedetails.intake_id) }}>Update </button>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card card-table">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-stripped table-hover datatable display" id="example">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th>Intake ID</th>
                                                    <th>Customer Name</th>
                                                    <th>Primary Contact</th>
                                                    <th>Secondary Contact</th>
                                                    <th>Device</th>
                                                    <th>Problem</th>
                                                    <th>Status</th>
                                                    <th class="text-end">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.data.map((item) => {
                                                        if (item.status == 'Pending') {
                                                            return (

                                                                <tr key={item.id}>
                                                                    <td><a href="view-estimate.html">{item.intake_id}</a></td>
                                                                    <td>
                                                                        <h2 class="table-avatar">
                                                                            <a href="profile.html"><img class="avatar avatar-sm me-2 avatar-img rounded-circle" src="assets/img/profiles/avatar-03.jpg" alt="User Image" />{item.name}</a>
                                                                        </h2>
                                                                    </td>
                                                                    <td>{item.primary_contact}</td>
                                                                    <td>{item.alternative_contact}</td>
                                                                    <td>{item.make} - {item.model}</td>
                                                                    <td>{item.problem}</td>

                                                                    <td><span class="badge bg-success-light">{item.status}</span></td>
                                                                    <td class="text-end">
                                                                        <div class="dropdown dropdown-action">
                                                                            <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-ellipsis-h"></i></a>
                                                                            <div class="dropdown-menu dropdown-menu-right">
                                                                                <a class="dropdown-item" href="#0" id="filter_intake_edit" onClick={e => { this.get_intake_details(item.intake_id) }}><i class="far fa-edit me-2"></i>Edit</a>
                                                                                <a class="dropdown-item" href="javascript:void(0);"><i class="far fa-trash-alt me-2"></i>Delete</a>
                                                                                <a class="dropdown-item" href="view-estimate.html"><i class="far fa-eye me-2"></i>View</a>
                                                                                <a class="dropdown-item" href="javascript:void(0);"><i class="far fa-file-alt me-2"></i>Convert to Invoice</a>
                                                                                <a class="dropdown-item" href="javascript:void(0);"><i class="far fa-paper-plane me-2"></i>Send Estimate</a>
                                                                                <a class="dropdown-item" href="javascript:void(0);"><i class="far fa-check-circle me-2"></i>Mark as Approved</a>
                                                                                <a class="dropdown-item" href="javascript:void(0);"><i class="far fa-times-circle me-2"></i>Mark as Returned</a>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    }
                                                    )
                                                }


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default PendingIntakes