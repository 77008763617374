import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Layout from '../Layout/Layout';
import Dashboard from '../Content/Dashboard';
import AddBrand from '../Content/Stock/Brand/AddBrand';
import Intake from '../Content/Intake/Intakes';
import Addintakes from '../Content/Intake/Addintakes';
import PendingIntakes from '../Content/Intake/PendingIntakes';
import ApprovedIntakes from '../Content/Intake/ApprovedIntakes';
import ReturnedIntakes from '../Content/Intake/ReturnedIntakes';
import Estimate from '../Content/Invoice/Estimate';
import Dynamictable from '../Content/Invoice/Dynamictable';
import MyContainer from '../Content/myContainer';
import Invoice from '../Content/Invoice/Invoice';
import AddCategory from '../Content/Stock/Category/AddCategory';
import Addvendor from '../Content/Stock/Vendor/AddVendor';
import Addproduct from '../Content/Stock/Product/AddProduct';
function App() {
    return (
        <div>
            <Layout />
            <Router>
                <Routes>
                    <Route path='/' element={<Dashboard />}></Route>
                    <Route path='addbrand' element={<AddBrand />}></Route>
                    <Route path='intake' element={<Intake />}></Route>
                    <Route path='addintake' element={<Addintakes />}></Route>
                    <Route path='pendingintake' element={<PendingIntakes />}></Route>
                    <Route path='approvedintake' element={<ApprovedIntakes />}></Route>
                    <Route path='returnedintake' element={<ReturnedIntakes />}></Route>
                    <Route path='estimate' element={<Estimate />}></Route>
                    <Route path='invoice' element={<Invoice />}></Route>
                    <Route path='dynamictable' element={<Dynamictable />}></Route>
                    <Route path='container' element={<MyContainer />}></Route>
                    <Route path='addcategory' element={<AddCategory/>}></Route>
                    <Route path='addvendor' element={<Addvendor/>}></Route>
                    <Route path='addvendor' element={<Addvendor/>}></Route>
                    <Route path='addproduct' element={<Addproduct/>}></Route>
                </Routes>
            </Router>
        </div>


    );
}

export default App;