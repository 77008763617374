import React from "react";
import { render } from "react-dom";
import axios from "axios";

import Invoice from "./Invoice";

class Estimate extends React.Component {

    state = {
        rows: [{}],
        amount: 0,
        discount: 0,
        bill_value: 0,
        net_amount: 0,

    };

    handleSubmit = event => {
        event.preventDefault();
        const rowcount = this.state.rows.length;
        let response = '';
        for (let i = 0; i < rowcount; i++) {
            axios.post('http://localhost:3001/api/invoice/', {
                intake_id: document.getElementById('intakeid').innerHTML,
                name: document.getElementById('name').innerHTML,
                primary_contact: document.getElementById('mobile').innerHTML,
                alternative_contact: document.getElementById('alternative_mobile').innerHTML,
                email: document.getElementById('email').innerHTML,
                address: document.getElementById('address').innerHTML,
                city: document.getElementById('city').innerHTML,
                pincode: document.getElementById('pincode').innerHTML,
                product: this.state.rows[i].product,
                brand: this.state.rows[i].brand,
                model: this.state.rows[i].model,
                serial_no: this.state.rows[i].serial_no,
                particulars: this.state.rows[i].particulars,
                quantity: this.state.rows[i].quantity,
                price: this.state.rows[i].price,
                amount: this.state.rows[i].amount,
                bill_value: document.getElementById('bill_value').innerHTML,
                discount: event.target.discount.value,
                net_amount: document.getElementById('net_amount').innerHTML,
            })

                .then(res => {
                    console.log(res.data);
                    console.log(res.data.message);
                    let ress = rowcount - 1;
                    if (i === ress) {
                        axios.patch('http://localhost:3001/api/intakes/', {
                            intake_id: document.getElementById('intakeid').innerHTML,
                            invoice_status: 2
                        }).then(res => {
                            alert(res.data.data);

                        })
                    }
                })
        }
    }
    handleChange = idx => e => {
        const { name, value } = e.target;
        const rows = [...this.state.rows];
        rows[idx][name] = value;
        const amount = rows[idx].quantity * rows[idx].price;
        rows[idx]['amount'] = amount;
        this.setState({
            rows,
        });

    };


    handleAddRow = () => {
        const item = {
            product: "",
            brand: "",
            model: "",
            serial_no: "",
            particulars: "",
            quantity: "",
            price: "",
            amount: "",
        };
        this.setState({
            rows: [...this.state.rows, item]
        });
    };
    handleRemoveRow = () => {
        this.setState({
            rows: this.state.rows.slice(0, -1)
        });
    };
    handleRemoveSpecificRow = (idx) => () => {
        const rows = [...this.state.rows]
        rows.splice(idx, 1)
        this.setState({ rows })
    }
    net_amount = () => {
        let subtotal = document.getElementById('bill_value').innerHTML;
        let discount = document.getElementById('discount').value;
        let net_amount = this.state.net_amount;
        net_amount = subtotal - discount;
        this.setState({
            net_amount,
        });
    }
    render() {
        const subtotal = this.state;
        const rowcount = subtotal.rows.length;
        let discount = this.state.discount;
        let bill_value = this.state.bill_value;
        let net_amount = this.state.net_amount;
        for (let i = 0; i < rowcount; i++) {
            if (subtotal.rows[i]['amount']) {
                bill_value = bill_value + subtotal.rows[i]['amount'];
                net_amount = bill_value;
            }
        }
        const stateval = this.props.intake;
        if (stateval.invoice_status === 1) {
            return <Invoice intake={this.props.intake} />
        }
        return (
            <div class="page-wrapper">
                <div class="content container-fluid">

                    <div class="page-header">
                        <div class="row">
                            <div class="col-sm-12">
                                <h3 class="page-title">Complete Estimation</h3>
                                <ul class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="/">Dashboard</a></li>
                                    <li class="breadcrumb-item"><a href="/estimate">Estimates</a></li>
                                    <li class="breadcrumb-item active">Add Estimate</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <form action="#" onSubmit={this.handleSubmit}>
                                        <div class="row">
                                            <div class="col-md-3  mt-3">
                                                <div class="form-group">
                                                    <label>INTAKE ID: <span name="intakeid" id="intakeid">{stateval.intake_id}</span></label>

                                                </div>
                                            </div>
                                            <div class="col-md-3  mt-3">
                                                <div class="form-group">
                                                    <label>Name :  <span id="name">{stateval.name}</span></label>
                                                </div>
                                            </div>
                                            <div class="col-md-3  mt-3">
                                                <div class="form-group">
                                                    <label>MOBILE : <span id="mobile">{stateval.primary_contact}</span></label>

                                                </div>
                                            </div>
                                            <div class="col-md-3 mt-3">
                                                <div class="form-group">
                                                    <label>Alternate Number: <span id="alternative_mobile">{stateval.alternative_contact}</span></label>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>ADDRESS: <span id="address">{stateval.address}</span></label>
                                                </div>
                                            </div>
                                            <div class="col-md-3 ">
                                                <div class="form-group">
                                                    <label>CITY :  <span id="city">{stateval.city}</span></label>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>PINCODE : <span id="pincode">{stateval.pincode}</span></label>

                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>EMAIL: <span id="email">{stateval.email}</span></label>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="table-responsive mt-4">
                                            <table class="table table-center table-hover" id="tbl">
                                                <thead>
                                                    <tr>
                                                        <th>Product</th>
                                                        <th>Brand</th>
                                                        <th>Model</th>
                                                        <th>Serial Number</th>
                                                        <th>Particulars</th>
                                                        <th>Quantity</th>
                                                        <th>Price</th>
                                                        <th>Amount</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody id="estimate_table">
                                                    {this.state.rows.map((item, idx) => (
                                                        <tr key={idx}>
                                                            <td>
                                                                <input type="text" class="form-control" name="product" value={this.state.rows[idx].product}
                                                                    onChange={this.handleChange(idx)} />
                                                            </td>
                                                            <td>
                                                                <input type="text" class="form-control" name="brand" value={this.state.rows[idx].brand}
                                                                    onChange={this.handleChange(idx)} />
                                                            </td>
                                                            <td>
                                                                <input type="text" class="form-control" name="model" value={this.state.rows[idx].model}
                                                                    onChange={this.handleChange(idx)} />
                                                            </td>
                                                            <td>
                                                                <input type="text" class="form-control" name="serial_no" value={this.state.rows[idx].serial_no}
                                                                    onChange={this.handleChange(idx)} />
                                                            </td>
                                                            <td>
                                                                <input type="text" class="form-control" name="particulars" value={this.state.rows[idx].particulars}
                                                                    onChange={this.handleChange(idx)} />
                                                            </td>
                                                            <td>
                                                                <input type="text" class="form-control" name="quantity" value={this.state.rows[idx].quantity}
                                                                    onChange={this.handleChange(idx)} />
                                                            </td>
                                                            <td>
                                                                <input type="text" class="form-control" name="price" value={this.state.rows[idx].price}
                                                                    onChange={this.handleChange(idx)} />
                                                            </td>
                                                            <td>
                                                                <input type="text" class="form-control" name="amount" value={this.state.rows[idx].amount}
                                                                    disabled />
                                                            </td>
                                                            <td class="add-remove text-end">
                                                                <i class="fas fa-plus-circle" onClick={this.handleAddRow}></i> <i
                                                                    class="fas fa-minus-circle" onClick={this.handleRemoveSpecificRow(idx)}></i>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="table-responsive mt-4">
                                            <table class="table table-stripped table-center table-hover">
                                                <thead></thead>
                                                <tbody>
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td class="text-end">Sub Total</td>
                                                        <td class="text-end"><span id="bill_value"> {bill_value} </span></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td class="text-end">Discount</td>
                                                        <td class="text-end"><input type="text" id="discount" class="form-control" onChange={this.net_amount} defaultValue={discount} /></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td class="text-end">Total</td>
                                                        <td class="text-end"><span id="net_amount">{this.state.net_amount === 0 ? net_amount : this.state.net_amount}</span></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-end mt-4">
                                            <button type="submit" class="btn btn-primary">Add Invoice</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Estimate;