import React, { useState } from "react";
import axios from 'axios';


class AddCategory extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            redirect: false,
            data: [],
            categorydetails: [],
        }
        this.handleChange = this.handleChange.bind(this);


    }



    addCategory = event => {
        event.preventDefault();
        axios.post('http://localhost:3001/api/category/', {
            category_name: this.state.categoryname,
        })
            .then(res => {
                console.log(res);
                console.log(res.data);
                // window.location = "/retrieve" //This line of code will redirect you once the submission is succeed
            })
    }
    editCategory(id) {
        axios.get('http://localhost:3001/api/category/' + id).then(res => {
            this.setState({
                redirect: true,
                categorydetails: res.data.data[0],
            });
        });


    }
    deleteRow(id, e) {
        axios.delete('http://localhost:3001/api/category/', {
            data: {
                id: id
            }
        })
            .then(res => {
                console.log(res);
                console.log(res.data);

                // const posts = this.state.posts.filter(item => item.id !== id);
                //this.setState({ posts });
            })

    }
    updateCategory(id) {
        axios.patch('http://localhost:3001/api/category/', {
           category_name: this.state.categoryname,
            id: id
        })
            .then(res => {

                console.log(res.data);
                // window.location = "/retrieve" //This line of code will redirect you once the submission is succeed
            })
    }
    // updateBrand(id) {
    //     axios.patch('http://localhost:3001/api/brand/', {
    //         brand_name: this.state.brandname,
    //         id: id.id
    //     })
    //         .then(res => {

    //             console.log(res.data);
    //             // window.location = "/retrieve" //This line of code will redirect you once the submission is succeed
    //         })
    // }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }




    componentDidMount() {
        //Get all users details in bootstrap table
        axios.get('http://localhost:3001/api/category/').then(res => {

            //Storing users detail in state array object
            this.setState({
                data: res.data.data,
            });
        });

    }

    render() {
        const buttonAlign = {
            marginTop: "1.6em"
        }
        const { data } = this.state;
        const id = this.state.categorydetails.id;
        return (
            <div class="page-wrapper">
                <div class="content container-fluid">

                    <div class="page-header">
                        <div class="row align-items-center">
                            <div class="col">
                                <h3 class="page-title">Category</h3>
                                <ul class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="/">Dashboard</a>
                                    </li>
                                    <li class="breadcrumb-item active">Category</li>
                                </ul>
                            </div>
                            <div class="col-auto">

                                <a class="btn btn-primary filter-btn" href="javascript:void(0);" id="filter_search">
                                    <i class="fas fa-plus"></i>
                                </a>
                            </div>
                        </div>
                    </div>


                    <div id="filter_inputs" class="card filter-card">
                        <div class="card-body pb-0">

                                <div class="row">
                                    <div class="col-sm-6 col-md-3">
                                        <div class="form-group">
                                            <label>Category Name</label>
                                            <input type="text" class="form-control" name="categoryname" defaultValue={this.state.categorydetails.category_name} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-3">
                                        <div class="form-group">
                                            {id
                                                ? <button type="submit" className="btn btn-primary text-end" style={buttonAlign} onClick={e => { this.updateCategory(this.state.categorydetails.id) }}>   Update </button>
                                                : <button type="submit" className="btn btn-primary text-end" style={buttonAlign} onClick={this.addCategory}>   Add </button>
                                            }
                                        </div>
                                    </div>

                                </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card card-table">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-center table-hover datatable">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th>S.No</th>
                                                    <th>Brand Name</th>
                                                    <th class="text-right">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.data.map((item) => {
                                                        return (

                                                            <tr key={item.id}>
                                                                <td>{item.id}</td>
                                                                <td>{item.category_name}</td>
                                                                <td className="text-right">
                                                                    <a href="#0" className="btn btn-sm btn-white text-success me-2" onClick={e => { this.editCategory(item.id) }} id="filter_search_edit"><i className="far fa-edit me-1"></i> Edit</a>
                                                                    <a href="#0" className="btn btn-sm btn-white text-danger me-2" onClick={(e) => this.deleteRow(item.id, e)}><i className="far fa-trash-alt me-1"></i>Delete</a>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                    )
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default AddCategory