import React from "react";
import Header from "./Header";
//import Dashboard from './Dashboard';

import Leftside from "./Leftside";
import Rightside from "./Rightside";
import SidebarLayout from "./SidebarLayout";
import SidebarSettings from "./SidebarSettings";

function Layout() {
    return (
        <div>
            <Header />
            <Leftside />
            <Rightside />
            <SidebarLayout />
            <SidebarSettings />
        </div>
    )
}

export default Layout